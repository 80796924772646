import React, { Component } from "react";
import styled from "styled-components";
import ic_close from "../../images/ic_close_white.svg";

const Bound = styled.div`
	padding: 14px 12px 16px 12px;
	width: 216px;
	height: 130px;
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;
	font-family: "SVN-Gilroy";
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	position: absolute;
	top: 280px;
	right: 106px;
	.row_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title {
			font-size: 24px;
			font-weight: bold;
			line-height: 1.08;
			color: #005993;
		}
		.close {
			cursor: pointer;
		}
	}
	.row_main {
		margin: 72px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.show_id {
			font-size: 12px;
			font-weight: 500;
			line-height: 1.3;
			color: #222222;
		}
		.div_btn {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			.btn {
				width: 88px;
				height: 36px;
				border-radius: 18px;
				background-color: #f31919;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
			}
		}
	}
`;

export default class PopupCouterSuccess extends Component {
	render() {
		const { data } = this.props;
		return (
			<Bound>
				<div className="row_header">
					<div className="title">{this.props.data.name}</div>
					<img
						className="close"
						onClick={this.props.onClosePopup}
						src={ic_close}
						alt="close"
					/>
				</div>
				<div className="row_main">
					<div className="show_id">ID: {data.id}</div>
					<div className="div_btn">
						<div
							className="btn"
							onClick={this.props.disconnectBranch}
						>
							THAY ĐỔI
						</div>
					</div>
				</div>
			</Bound>
		);
	}
}
