import React, { Component } from "react";
import styled from "styled-components";
//images
import ic_close from "../../images/ic_close_white.svg";
import { branchCounter } from "../../data/mockData";
// action
import { connect } from "react-redux";
import { connectLcdAction } from "../../action/connectLcdAction";
const Bound = styled.div`
	padding: 16px 12px;
	width: 240px;
	height: auto;
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
	background-color: #ffffff;
	font-family: "SVN-Gilroy";
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	.row_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title {
			font-size: 16px;
			font-weight: bold;
			line-height: 1.19;
			color: #000000;
		}
		.ic_close {
			cursor: pointer;
		}
	}
	.form_login {
		margin: 26px 0 0 0;
		.title {
			text-transform: uppercase;
			font-size: 11px;
			font-weight: bold;
			line-height: 1.26;
			color: #005993;
			margin-top: 13px;
		}
		input {
			margin: 5px 0 0 0;
			padding: 14px 10px;
			width: calc(100% - 20px);
			height: 15px;
			border-radius: 4px;
			border: solid 0.7px #c8c8c8;
			background-color: #ffffff;
			&::placeholder {
				opacity: 0.25;
				font-size: 14px;
				font-weight: 500;
				line-height: 1.2;
				color: #222222;
			}
		}
		.div_error {
			margin: 23px 0 22px 0;
			height: 23px;
			.error {
				font-size: 14px;
				font-weight: 500;
				line-height: 1.2;
				color: #f31919;
			}
		}
		.div_btn {
			display: flex;
			justify-content: center;
			align-items: center;
			.btn_login {
				width: 100px;
				height: 36px;
				border-radius: 18px;
				background-color: #005993;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
				border: none;
				outline: none;
				cursor: pointer;
			}
		}
	}
`;

class PopupCouterLoginV2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: "",
			pinCode: "",
			idConnect: "",
			branchCounter: branchCounter,
			isActive: false,
		};
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	onSubmit = async (e) => {
		e.preventDefault();
		const { idConnect, pinCode } = this.state;
		let id = idConnect.replace(/\s+/g, "");
		let pin = pinCode.replace(/\s+/g, "");
		if (idConnect === "" || pinCode === "") {
			this.setState({
				error: "Vui lòng nhập đủ các trường",
			});
		} else {
			await this.props.connectLcdAction(id, pin);
			let data = await this.props.connectLcdReducer;
			if (data && data.err === null) {
			} else {
				this.setState({
					error: "Mã ID không tồn tại",
				});
			}
		}
	};
	render() {
		return (
			<Bound>
				<div className="row_header">
					<p className="title">Cấu hình thiết bị</p>
					<img
						className="ic_close"
						onClick={this.props.onClosePopup}
						src={ic_close}
						alt="close"
					/>
				</div>
				<form className="form_login" onSubmit={this.onSubmit}>
					<p className="title">MÃ ID THIẾT BỊ</p>
					<input
						name="idConnect"
						onChange={this.onChange}
						type="text"
						placeholder="Nhập ID"
					/>
					<p className="title">PINCODE</p>
					<input
						name="pinCode"
						onChange={this.onChange}
						type="text"
						placeholder="Nhập ID"
					/>
					<div className="div_error">
						<span className="error">{this.state.error}</span>
					</div>
					<div className="div_btn">
						<button type="submit" className="btn_login">
							XÁC NHẬN
						</button>
					</div>
				</form>
			</Bound>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		connectLcdReducer: state.connectLcdReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		connectLcdAction: (connectID, pass) =>
			dispatch(connectLcdAction(connectID, pass)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupCouterLoginV2);
