import React, { Component } from "react";
import styled from "styled-components";

//component
import PopupConnectBranchV2 from "../Popup/PopupConnectBranchV2";

const Bound = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	right: -240px;
	width: 240px;
	border-radius: 10px;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
	background-color: #ffffff;
	transition: right 0.3s;
	z-index: 11;
`;

export default class ActionPopupContainer extends Component {
	state = {
		isConnected: false,
		indexActive: "",
	};
	reset() {
		this.setState({ indexActive: "" });
	}
	componentWillReceiveProps(n) {
		if (n) {
			this.setState({ isConnected: n.isConnected });
		}
	}
	onChangeActive(key) {
		this.props.onActive(key);
		this.setState({
			indexActive: key,
		});
	}
	onClosePopup = () => {
		this.props.onClosePopup();
	};
	render() {
		return (
			<Bound id="action-menu-id">
				<PopupConnectBranchV2
					disconnectInter={this.props.disconnectInter}
					branch={this.props.branch}
					onClosePopup={() => {
						this.onClosePopup();
					}}
					dataCounter={this.props.dataCounter}
					disconnectBranch={this.props.disconnectBranch}
				/>
			</Bound>
		);
	}
}
