import React, { Component } from "react";
import styled from "styled-components";

const Bound = styled.div`
	width: 453px;
	height: 254px;
	position: absolute;
	background: #fff;
	border-radius: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 0px 30px;
	.title {
		font-family: SVN-Gilroy;
		font-size: 24px;
		line-height: 26px;
		color: #f31919;
		font-weight: bold;
	}
	.btn-confirm {
		background: #f31919;
		border-radius: 18px;
		width: 150px;
		height: 36px;
		cursor: pointer;
		border-radius: 18px;
		outline: none;
		border-width: initial;
		border-style: none;
		border-color: initial;
		border-image: initial;
		font-family: SVN-Gilroy;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		color: rgb(255, 255, 255);
	}
`;

export default class PopupLogout403 extends Component {
	onClick = () => {
		this.props.onClosePopup();
	};
	render() {
		return (
			<Bound>
				<div className="title">
					Quá thời gian giao dịch trong hệ thống
				</div>
				<p>Bạn vui lòng đăng nhập lại hệ thống</p>
				<button className="btn-confirm" onClick={this.onClick}>
					KẾT THÚC GIAO DỊCH
				</button>
			</Bound>
		);
	}
}
