import styled from 'styled-components'

const OverlayWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.6);
    z-index: 20;
`;

const FillClientNameWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    background: #ffffff;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 32px 0 46px 0;

    .web-title {
        color: #005993;
        text-align: center;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 26px;
    }

    .form-title {
        margin-top: 19px;
        color: #222222;
        text-align: center;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 128%;
    }

    .field-input {
        width: 80%;
        margin: 42px auto 0;

        .field-input__title {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 126%;
            color: #005993;
            text-transform: uppercase;
        }

        input {
            margin-top: 5px;
            box-sizing: border-box;
            width: 100%;
            border-radius: 4px;
            border: none;
            background: #fafafa;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
            color: #222222;
            padding: 11px 9px;
            ::placeholder {
                color: #bebebe;
            }
        }
    }

    .error {
        width: 80%;
        margin: 20px auto 0;
        min-height: 36px;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 128%;
        color: #f31919;
    }

    .btn-login {
        display: block;
        margin: 30px auto 0;
        padding: 8px 12px;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 12.5px;
        line-height: 120%;
        letter-spacing: 0.25px;
        color: #ffffff;
        text-transform: uppercase;

        background: #005993;
        border-radius: 18px;
        border: none;
        outline: none;

        :hover {
            cursor: pointer;
        }
    }
`

export { OverlayWrapper, FillClientNameWrapper };