import * as types from '../action/const'

const initialState = {
    TicketNumber:'',
    type: ''
}

export default function getCompleteTicketReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_COMPLETE_TICKET:
            return {
                ...state,
                TicketNumber:action.data.TicketNumber,
                type: 'COMPLETE'
            }
        case types.GET_COMPLETE_TICKET_ERR:
            return {
                ...state,
                err: 'ERR',
                TicketNumber: ''
            }
        default:
            return state
    }
}