
import * as types from './const'


export const getCompleteTicketAction = (data) => async dispatch => {
    if (data) {
        dispatch({
            type: types.GET_COMPLETE_TICKET,
            data
        })
    }else{
        dispatch({
            type: types.GET_COMPLETE_TICKET_ERR,
            
        })
    }
}

