import React, { Component } from "react";
import styled from "styled-components";
const Bound = styled.div`
	display: flex;
	width: auto;
	justify-content: space-between;
	align-items: center;
	margin-right: ${(props) => (props.marginRight ? props.marginRight : 200)}px;
	.ticky {
		font-family: SVN-Gilroy;
		font-weight: bold;
		line-height: 1.2;
		color: #222222;
		font-size: 60px;
	}

	.icon {
		width: 20px;
		height: 31.4px;
		background-color: #7ed3f7;
		clip-path: polygon(0 0, 0% 100%, 100% 50%);
		margin: 0px 10px 0 10px;
	}
	.counter {
		font-family: SVN-Gilroy;
		font-weight: bold;
		line-height: 1.2;
		color: #d71249;
		font-size: 60px;
	}
	&:last-child {
		margin-right: 0px;
	}
`;

export default class TicketNow extends Component {
	n(n) {
		if (n > 0) {
			return n > 9 ? "" + n : "0" + n;
		} else return 0;
	}
	render() {
		const { item } = this.props;
		return (
			<Bound
				width={this.props.width}
				marginRight={this.props.marginRight}
			>
				<div className="ticky">{this.n(item.TicketNumber)}</div>
				<div className="icon"></div>
				<div className="counter">{this.n(item.CounterNumber)}</div>
			</Bound>
		);
	}
}
