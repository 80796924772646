import { combineReducers } from 'redux'
import connectLcdReducer from './connectLcdReducer'
import getConnectTokenReducer from './getConnectTokenReducer'
import getServiceTicketReducer from './getServiceTicketReducer'
import getMissTicketReducer from './getMissTicketReducer'
import getAdvertiseImgReducer from './getAdvertiseImgReducer'
import currentTicketReducer from './currentTicketReducer'
import getCompleteTicketReducer from './getCompleteTicketReducer'
import disconnectReducer from './disconnectReducer'
import logout403Reducer from './logoutReducer'
import commonReducer from './commonReducer'

const appReducer = combineReducers({
  //  reducer
  connectLcdReducer,
  getConnectTokenReducer,
  getServiceTicketReducer,
  getMissTicketReducer,
  getAdvertiseImgReducer,
  currentTicketReducer,
  getCompleteTicketReducer,
  disconnectReducer,
  logout403Reducer,
  commonReducer
})

const rootReducer = (state, action) => {
  return appReducer(state, action);
}



export default rootReducer