import Axios from "axios"
import { host } from '../host'
import * as types from './const'
const apigetAdvertise = host + 'lcd/get-advertise-at-office'

export const getAdvertiseImgAction = (offID) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    OfficeID: offID
  })

  try {
    const res = await Axios.post(apigetAdvertise, queryDataFormat)
    // console.log(res)
    const { data } = res
    if (data) {
      dispatch({
        type: types.GET_ADVERTISE_IMG,
        data: data
      })
    }
  }
  catch (err) {
    console.log( err)
    dispatch({
      type: types.DISCONNECT,
      err: 502
    })
    if(err.response&&err.response.status===403){
      dispatch({
        type: types.LOGOUT_LCD
      })
    }
  }
}
