import * as types from '../action/const'

const initialState = {
    err:null
}

export default function disconnectReducer(state = initialState, action) {
    
    switch (action.type) {
        case types.DISCONNECT:
            return {
                ...state,
                err: action.err
            }
        default:
            return state
    }
}