import React from "react";
import styled from "styled-components";

const Bound = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 8px;
	color: #005993;
	@media only screen and (max-width: 600px) {
		font-size: 18px;
		margin-bottom: 5px;
	}
`;

export default class CoundownNumber extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			count: props.time,
			running: false,
		};
	}
	componentDidMount() {
		this.handleStart();
	}

	handleStart() {
		this.timer = setInterval(() => {
			const newCount = this.state.count - 1;
			this.setState({ count: newCount >= 0 ? newCount : 0 }, () => {
				this.props.getTime(this.state.count);
				if (this.state.count === 0) {
					clearInterval(this.timer);
				}
			});
		}, 1000);
	}
	componentWillUnmount() {
		clearInterval(this.timer);
	}
	functionX(sec_num) {
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor((sec_num - hours * 3600) / 60);
		var seconds = sec_num - hours * 3600 - minutes * 60;

		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return hours + " : " + minutes + " : " + seconds;
	}
	format(time) {
		let seconds = time % 60;
		let minutes = Math.floor(time / 60);
		let hours = Math.floor(time / 3600);
		hours = hours.toString().length === 1 ? "0" + hours : hours;
		minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
		seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
		return hours + " : " + minutes + " : " + seconds;
	}

	render() {
		const { count } = this.state;
		return <Bound>{this.functionX(count)}</Bound>;
	}
}
