import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import IpValidate from "ip-validator";

import PopupCouterLoginV2 from "./PopupCouterLoginV2";
import PopupCouterSuccessV2 from "./PopupCouterSuccessV2";
import Modal from "../../helper/Modal";
import PopupFillClientName from "./PopupFillClientName";

// action
import { getNameOfClient } from "../../action/commonAction";
import { getDetailsLCD } from "../../action/connectLcdAction";

const Bound = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: transparent;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
`;

class PopupConnectBranchV2 extends Component {
	constructor(props) {
		super(props);
		this.isMouseEnterPopup = false;
		this.state = {
			branch: null,
		};
	}
	
	onClosePopup = () => {
		this.props.onClosePopup();
	};

	render() {
		let content = null;
		if (this.state.branch) {
			content = (
				<PopupCouterSuccessV2
					disconnectInter={this.props.disconnectInter}
					data={this.state.branch}
					disconnectBranch={this.props.disconnectBranch}
					onClosePopup={this.onClosePopup}
				/>
			);
		} else {
			content = (
				<PopupCouterLoginV2
					onClosePopup={this.onClosePopup}
				/>
			);
		}
		return (
			<Bound
				onMouseDown={() => {
					
				}}
			>
				{content}
				<Modal isShow={this.state.isShowFillClientName}>
					<PopupFillClientName
						closePopup={() =>
							this.setState({ isShowFillClientName: false })
						}
					/>
				</Modal>
			</Bound>
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.connectLcdReducer.LcdID !==
			this.props.connectLcdReducer.LcdID
		) {
			this.setState({ branch: this.props.connectLcdReducer });
			this.props.dataCounter(this.props.connectLcdReducer);
		}

		if (
			prevProps.commonReducer.clientName !==
			this.props.commonReducer.clientName
		) {
			if (this.props.commonReducer.clientName === "EMPTY") {
				this.setState({ isShowFillClientName: true });
				return;
			}

			const clientNameIsIp =
				IpValidate.ipv4(this.props.commonReducer.clientName) ||
				IpValidate.ipv6(this.props.commonReducer.clientName);
			if (clientNameIsIp) {
				this.setState({ isShowFillClientName: true });
				return;
			}
			this.props.getDetailsLCD(this.props.commonReducer.clientName);
		}
	}

	componentDidMount() {
		this.props.getNameOfClient();
	}
}

const mapStateToProps = (state) => ({
	connectLcdReducer: state.connectLcdReducer,
	commonReducer: state.commonReducer,
});

const mapDispathToProps = (dispatch) => ({
	getNameOfClient: () => dispatch(getNameOfClient()),
	getDetailsLCD: (deviceMac) => dispatch(getDetailsLCD(deviceMac)),
});

export default connect(
	mapStateToProps,
	mapDispathToProps
)(PopupConnectBranchV2);
