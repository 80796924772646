import * as types from '../action/const'

const initialState = {
    CurrentInCounter: [],
    MissCall: []
}

export default function currentTicketReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CURRENT_TICKET:

            return {
                ...state,
                CurrentInCounter: action.data.CurrentInCounter && action.data.CurrentInCounter.length > 0 ? action.data.CurrentInCounter : [],
                MissCall: action.data.MissCall && action.data.MissCall.length > 0 ? action.data.MissCall : [],
                err: null
            }
        case types.DISCONNECT:
            return {
                ...state,
                CurrentInCounter: [],
                MissCall: [],
                err: action.err
            }
        default:
            return state
    }
}