import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
//image
import logo_vietinbank from "../../images/logo_vietinbank.svg";
import line from "../../images/mark.svg";

const Bound = styled.div`
	display: flex;
	flex-direction: column;
	height: 10vh;
	background-color: #ffffff;
	font-family: "SVN-Gilroy";
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	flex-shrink: 0;
	.header {
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 56px;
		overflow: hidden;
		background: rgb(126, 211, 247);
		.logo {
		}
		.title {
			font-size: ${(props) => (props.active ? "30px" : "28px")};
			font-weight: ${(props) => (props.active ? "bold" : "500")};
			line-height: 1.2;
			color: #005993;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			.moment {
				color: #005993;
				margin-right: 40px;
			}
		}
	}
	.line {
		display: flex;
		width: 100%;
		height: 12px;
		background-image: url(${line});
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.line-vtb {
		min-width: 100%;
	}
`;

export default class HeaderContainer extends Component {
	state = {
		branch: this.props.branch,
	};
	componentDidMount() {
		this.getTimeNow = setInterval(() => {
			this.setState({ time: moment().format("HH:mm") });
		}, 1000);
	}
	componentWillReceiveProps(n) {
		if (n) {
			this.setState({
				branch: n.branch,
			});
		}
	}
	componentWillUnmount() {
		clearInterval(this.getTimeNow);
	}
	render() {
		return (
			<Bound active={this.state.branch ? true : false}>
				<div className="header">
					<p className="title">
						<span className="moment">{this.state.time}</span>
						<b>
							{this.state.branch
								? this.state.branch.OfficeName
								: "Chưa cấu hình ..."}
						</b>
					</p>
					<div className="logo">
						<img src={logo_vietinbank} alt="logo" />
					</div>
				</div>
				<div className="line" />
			</Bound>
		);
	}
}
