// Audio call Ticket by -- minisecond
export const timeWaitbyTwoAudio = 4000



// IMG Adverti by -- minisecond
export const setTimeSpeed = 5000
export const setTimeAutoPlaySpeed = 7000


// Time change Page by -- second
export const timeChangePage = 30000