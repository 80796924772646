
export const CONNECT_LCD = 'CONNECT_LCD'
export const CONNECT_LCD_ERR = 'CONNECT_LCD_ERR'

export const GET_CONNECT_TOKEN = 'GET_CONNECT_TOKEN'
export const GET_CONNECT_TOKEN_ERR = 'GET_CONNECT_TOKEN_ERR'

export const GET_SERVICE_TICKET = 'GET_SERVICE_TICKET'
export const GET_SERVICE_TICKET_ERR = 'GET_SERVICE_TICKET_ERR'

export const GET_MISS_TICKET = 'GET_MISS_TICKET'
export const GET_MISS_TICKET_ERR = 'GET_MISS_TICKET_ERR'

export const GET_ADVERTISE_IMG = 'GET_ADVERTISE_IMG'
export const GET_ADVERTISE_IMG_ERR = 'GET_ADVERTISE_IMG_ERR'

export const GET_CURRENT_TICKET = 'GET_CURRENT_TICKET'
export const GET_CURRENT_TICKET_ERR = 'GET_CURRENT_TICKET_ERR'

export const GET_COMPLETE_TICKET = 'GET_COMPLETE_TICKET'
export const GET_COMPLETE_TICKET_ERR = 'GET_COMPLETE_TICKET_ERR'

export const LOGOUT_LCD = 'LOGOUT_LCD'
export const LOGOUT_LCD_ERR = 'LOGOUT_LCD_ERR'

export const DISCONNECT = 'DISCONNECT'

export const GET_CLIENT_NAME = 'GET_CLIENT_NAME'