import * as types from '../action/const'

const initialState = {
    Token: '',
    err: null
}

export default function getConnectTokenReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CONNECT_TOKEN:
            return {
                ...state,
                Token: action.data.Token,
                err: null
            }
        case types.GET_CONNECT_TOKEN_ERR:
            return {
                ...state,
                Token:null,
                err: action.err
            }
        default:
            return state
    }
}