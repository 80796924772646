import * as types from '../action/const'

const initialState = {
    AdvertiseList:null
}

export default function getAdvertiseImgReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ADVERTISE_IMG:
            return {
                ...state,
                AdvertiseList:action.data.AdvertiseList || [],
            }
        case types.DISCONNECT:
            return {
                ...state,
                err: action.err,
                AdvertiseList:[]
            }
        default:
            return state
    }
}