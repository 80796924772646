import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

// link func
import ic_phone from "../../images/phone_wait.svg";
import ic_girl from "../../images/ic_girl.svg";

// action
import { getAdvertiseImgAction } from "../../action/getAdvertiseImgAction";
import { currentTicketAction } from "../../action/currentTicketAction";

const Bound = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	background-image: linear-gradient(to top, #7ed3f7, #b0def6);
	overflow: hidden;
	.main-image {
		width: 35%;
		position: absolute;
		bottom: -5px;
		left: 10%;
		img {
			width: 100%;
		}
	}
	.title-hello {
		font-family: SVN-Gilroy;
		font-size: 60px;
		font-weight: bold;
		line-height: 1.2;
		color: #005993;
		text-align: right;
		position: absolute;
		right: 5%;
		top: 10%;
	}
	.phone-call {
		text-align: right;
		position: absolute;
		right: 5%;
		bottom: 10%;
		.phone {
			display: flex;
			.number {
				font-family: SVN-Gilroy;
				font-size: 48px;
				font-weight: bold;
				line-height: 1.2;
				color: #d71249;
				margin-left: 10px;
			}
		}
		.web {
			font-family: SVN-Gilroy;
			font-size: 34px;
			font-weight: 500;
			line-height: 1.3;
			color: #222222;
		}
	}
`;

class WaitingPage extends Component {
	constructor() {
		super();
		this.timer = null;
		this.state = {};
	}
	componentDidMount() {
		let dataBranch = localStorage.getItem("dataBranch");
		this.timer = setInterval(async () => {
			if (dataBranch) {
				await this.props.currentTicketAction(
					JSON.parse(dataBranch).OfficeID
				);
				const { MissCall, CurrentInCounter } = await this.props.currentTicket;
				if (MissCall.length > 0 || CurrentInCounter.length > 0) {
					this.props.onChangePage(3);
				} else {
					await this.props.getAdvertiseImgAction(
						JSON.parse(dataBranch).OfficeID
					);
					const { AdvertiseList } = await this.props.getAdvertiseImg;
					if (AdvertiseList.length > 0) {
						this.props.onChangePage(2);
					}
				}
			}
		}, 60000);
	}
	componentWillReceiveProps(newProps) {
		if (this.props.disconnectReducer !== newProps.disconnectReducer) {
			this.props.getDisconnectInter(newProps.disconnectReducer.err);
		}
	}
	componentWillUnmount() {
		clearInterval(this.timer);
	}
	render() {
		return (
			<Bound>
				<div className="main-image">
					<img src={ic_girl} alt="ic_girl" />
				</div>
				<div className="title-hello">
					<div>VIETINBANK XIN</div>
					<div>KÍNH CHÀO QUÝ KHÁCH</div>
				</div>
				<div className="phone-call">
					<div className="phone">
						<img src={ic_phone} alt="ic_phone" />
						<div className="number">1900 558 868</div>
					</div>
					<div className="web">www.vietinbank.vn</div>
				</div>
			</Bound>
		);
	}
}
const mapDispathToProps = (dispatch) => {
	return {
		getAdvertiseImgAction: (offID) =>
			dispatch(getAdvertiseImgAction(offID)),
		currentTicketAction: (offID) => dispatch(currentTicketAction(offID)),
	};
};
const mapStateToProps = (state) => ({
	getAdvertiseImg: state.getAdvertiseImgReducer,
	disconnectReducer: state.disconnectReducer,
	currentTicket: state.currentTicketReducer,
});
export default connect(mapStateToProps, mapDispathToProps)(WaitingPage);
